<template>
  <v-navigation-drawer
   
   app
   clipped
   v-model="DRAWER_STATE"
   :mini-variant="!DRAWER_STATE"
   :width="sidebarWidth"
   :permanent="$vuetify.breakpoint.mdAndUp"
   :temporary="$vuetify.breakpoint.smAndDown"
   :mini-variant-width="sidebarMinWidth"
   :class="{'drawer-mini': !DRAWER_STATE}">
   <v-list>
    <template v-for="(item, i) in items">
     <v-row
      v-if="item.heading"
      :key="item.heading"
      align="center">
      <v-col
       cols="6"
       class="py-5">
       <span style="padding-left: 32px" class="text-body-1 subheader" :class="(item.heading && DRAWER_STATE) ? 'show ' : 'hide'">
        {{ item.heading }}
       </span>
      </v-col>
      <v-col
       cols="6"
       class="text-center">
      </v-col>
     </v-row>
     <v-divider
      v-else-if="item.divider"
      :key="i"
      dark
      class="my-4"></v-divider>
     <v-list-group 
      class="haveChild"
      color="primary"
      v-else-if="item.children && DRAWER_STATE"
      :key="item.title"
      v-model="item.model">
      <template v-slot:prependIcon>
       <v-icon >{{item.icon}}</v-icon>
      </template>
      <template v-slot:activator>
       <v-list-item-content>
        <v-list-item-title class="grey--text">
         {{ item.title }}
        </v-list-item-title>
       </v-list-item-content>
      </template>
      <v-list-item
       v-for="(child, i) in item.children"
       :key="i"
       :to="child.link"
       link>
       <v-list-item-action v-if="child.icon">
        <v-icon >{{ child.icon }}</v-icon>
       </v-list-item-action>
       <v-list-item-content>
        <v-list-item-title class="grey--text">
         {{ child.title }}
        </v-list-item-title>
       </v-list-item-content>
      </v-list-item>
     </v-list-group>
     <v-list-item
      color="primary"
      v-else
      :key="item.text"
      :href="item.href ? item.href : null"
      :to="item.link === '#' ? null : item.link"
      link>
      <v-list-item-action class="m-2">
       <v-icon
        
        :color="item.color ? item.color : ''">{{ item.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
       <v-list-item-title
        class="grey--text body-2 font-weight-bold"
        link>
        {{ item.title }}
       </v-list-item-title>
      </v-list-item-content>
     </v-list-item>
    </template>
   </v-list>
  </v-navigation-drawer>
 </template>
<script>
import {mapActions, mapState} from 'vuex'

  export default {
    props: {
        source: String,
    },
    data(){
      return {
        items: [
          { title: 'dashboard', icon: 'mdi-home', link: '/dashboard' },
          // { title: 'Typography', icon: 'mdi-format-size', link: '/typography' },
          // { title: 'Tables', icon: 'mdi-grid-large', link: '/tables' },
          // { title: 'Notifications', icon: 'mdi-bell-outline', link: '/notifications' },
          // {
          //   title: 'UI Elements',
          //   icon: 'mdi-image-filter-none',
          //   link: '/icons',
          //   model: false,
          //   children: [
          //     { title: 'Icons', icon: 'mdi-circle-small', link: '/icons'},
          //     { title: 'Charts', icon: 'mdi-circle-small', link: '/charts'},
          //   ],
          // },
          { divider: true },

          {
            title: 'orders',
            icon: 'mdi-truck',
            link: '/orders',
            model: false,
            children: [
              { title: 'all orders', icon: 'mdi-order-bool-ascending', link: '/orders'},
              { title: 'faill orders', icon: 'mdi-alert-circle-outline', link: '/fail-orders'},
              { title: 'friends', icon: 'mdi-sticker-remove-outline', link: '/fail-friends'},
              { title: 'faill rest', icon: 'mdi-arrow-u-right-bottom-bold', link: '/fail-rest'},
              { title: 'orders deleted', icon: 'mdi-delete', link: '/orders_deleted'},
            ],
          },
          {
            title: 'bills',
            icon: 'mdi-cash-multiple',
            link: '/bills-all',
            model: false,
            children: [
              { title: 'all bills', icon: 'mdi-table', link: '/bills-all'},
              { title: 'Done bills', icon: 'mdi-check-circle-outline', link: '/bills-Done'},
              { title: 'Un Done bills', icon: 'mdi-close-circle-outline', link: '/bills-unDone'},
              { title: 'bills deleted', icon: 'mdi-delete', link: '/bills_deleted'},
              
            ],
          },
          {
            title: 'bank',
            icon: 'mdi-bank',
            link: '/bank-order',
            model: false,
            children: [
            { title: 'bank orders', icon: 'mdi-cart', link: '/bank-order'},
            { title: 'bank veriefied', icon: 'mdi-check-decagram', link: '/bank-verified'},
            { title: 'bank services', icon: 'mdi-star', link: '/bank-service'},
            { title: 'bank deleted', icon: 'mdi-delete', link: '/bank_deleted'},
             
            ],
          },
          {
            title: 'Universities',
            icon: 'mdi-school-outline',
            link: '/universities-orders-all',
            model: false,
            children: [
              { title: 'all orders', icon: 'mdi-archive', link: '/universities-orders-all'},
              { title: 'Done order', icon: 'mdi-check-circle-outline', link: '/universities-orders-Done'},
              { title: 'Un Done order', icon: 'mdi-close-circle-outline', link: '/universities-orders-unDone'},
              
            ],
          },
          {
            title: 'customers',
            icon: 'mdi-account-multiple',
            link: '/customers-all',
            model: false,
            children: [
              { title: 'all customers ', icon: 'mdi-account-multiple-check', link: '/customers-all'},
              { title: 'Chats ', icon: 'mdi-chat-outline', link: '/chats'},

            ],
          }
          ,{
            title: 'money',
            icon: 'mdi-cash',
            link: '/money',
            model: false,
            children: [
              { title: 'All Money', icon: '', link: '/money'},
              { title: 'Update Money Order', icon: '', link: '/MoneyUpdate'},
              { title: 'Money Failed', icon: "", link: '/MoneyFailed'},
              { title: 'Money In Progress', icon: "", link: '/MoneyInProgress'},
              { title: 'Money Progress Paypal', icon: "", link: '/MoneyInProgressPaypal'},
              { title: 'Money Matching', icon: "", link: '/MoneyMatching'},
              { title: 'money deleted', icon: 'mdi-delete', link: '/money_deleted'},

            ],
          },
          { divider: true }
          ,{
            title: 'coupons',
            icon: 'mdi-ticket-percent',
            link: '/coupons-all',
            model: false,
            children: [
              { title: 'all coupons ', icon: 'mdi-ticket', link: '/coupons-all'},
             
            ],
          }
          ,{
            title: 'constant',
            icon: 'mdi-cogs',
            link: '/system-constant',
            model: false,
            children: [
            { title: 'system keys ', icon: 'mdi-cog-box', link: '/system-constant'},
            { title: 'Currencies', icon: 'mdi-currency-usd', link: '/currencies'},

            ],
          }
          ,{
            title: 'PayPal Accounts',
            icon: 'mdi-account',
            link: '/paypal_accounts',
            model: false,

          }
          ,{
            title: 'PayPal Response',
            icon: 'mdi-credit-card-settings-outline',
            link: '/paypal_response',
            model: false,

          }
          ,{
            title: 'Wallets',
            icon: 'mdi-wallet',
            link: '/wallets',
            model: false,

          }
          ,{
            title: 'Payment Methods',
            icon: 'mdi-cards',
            link: '/payment_methods',
            model: false,

          }
          ,{
            title: 'Taxes',
            icon: 'mdi-percent',
            link: '/taxes',
            model: false,

          }
          ,{
            title: 'messages',
            icon: 'mdi-message',
            link: '/messages',
            model: false,
            children: [
            { title: 'all messages', icon: 'mdi-message-alert', link: '/messages'},
            { title: 'all Sms', icon: 'mdi-message-text', link: '/Sms'},
            
            ],
          },          { divider: true }

          
        ],
        sidebarWidth: 280,
        sidebarMinWidth: 96
      }
    },
    computed: {
      ...mapState(['drawer']),
      DRAWER_STATE: {
        get() {
          return this.drawer
        },
        set(newValue) {
          if (newValue === this.drawer) return;
          this.TOGGLE_DRAWER();
        }
      }
    },
    methods: {
      ...mapActions([ 'TOGGLE_DRAWER' ]),
    }
  }
</script>

<style src="./Sidebar.scss" lang="scss"/>
