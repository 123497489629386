<template>
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title>
          <span>{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">#{{ this.form.id }}</div>
                  <v-list-item-title class="text-overline mb-1">
                    Customer : {{ this.form.customer_id }}
                  </v-list-item-title>
                  <v-list-item-title class="text-overline mb-1">
                    university : {{ this.form.university.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    >student name : {{ this.form.student_name }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-col cols="6" sm="6" md="6">
                <v-btn width="100%" depressed type="submit" dark color="green"
                @click="
                    (val) => {
                      this.form.status = !this.form.status;
                    }
                  "
                >
                  yes
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-btn
                  width="100%"
                  depressed
                  dark
                  color="red"
                  @click="$emit('dialogForm',false)"
                >
                  No
                </v-btn></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import {
    required,
    maxLength,
    minLength,
    email,
    sameAs,
  } from "vuelidate/lib/validators";
  import { mapGetters, mapActions } from "vuex";
  export default {
    mixins: [validationMixin],
    props: {
      api: Object,
      isNew: Boolean,
      page:{
        default:1
      },
      newItemLabel: {
        default: "عنصر",
      },
    },
    validations: {
      form: {
        value: {
          required,
        },
      },
    },
    data() {
      return {
        value_error_msgs: {
          required: "This Field is Required.",
        },
      };
    },
    computed: {
      ...mapGetters(["getForm"]),
      valueErrors() {
        const errors = [];
        if (!this.$v.form.value.$dirty) return errors;
        !this.$v.form.value.required &&
          errors.push(this.value_error_msgs.required);
        return errors;
      },
      form() {
        return this.getForm;
      },
      formTitle() {
        return  this.newItemLabel;
      },
    },
    watch: {
      model() {},
    },
    methods: {
      save() {
          this.$store.dispatch("sendFormMakePay", {
            api: this.api,
            form: this.form,
            isNew: this.isNew,
            page:this.page
  
          });
          this.$emit("dialogForm", false);
        
      },
    },
  };
  </script>
  