<template>
  <v-container fluid class="icons-page">
    <v-row no-gutters class="d-flex justify-space-between mt-2 mb-2">
      <v-col>
        <!-- List -->
        <Table
          newItemLabel="All Orders"
          :filter="filter"
          :title="title"
          :api="api"
          :create="create"
          :del="del"
          :edit="edit"
          :resend="resend"
          :message="message"
          :block="block"
          :recovery_update_order="recovery_update_order"
          :exchange_sim="exchange_sim"
          :headers="headers"
          @openForm="setForm"
          @update_page="(val )=>{page = val}"

        ></Table>
        <!-- Form -->
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_form" max-width="800px">
      <OrderForm
        v-if="dialog_form"
        :fields="fields"
        newItemLabel="Order"
        :isNew="isNew"
        :id="id"
        :page="page"
        :api="api"
        @dialogForm="dialog_form = false"
      ></OrderForm>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isNew: true,
      dialog_form: false,
      filter: "orders",
      page:1,
      api: {
        getAll: "Orders",
        filter: "Orders",
        resend: "PayingButNotReceive",
        message: "PayingJustSend",
        exchange_sim: "changeTypeofSIM",
        edit: "updateOrder",
        delete: "order_delete?id",
        block: "block_order",
        type:"UNITE",
        recovery_update_order:"recovery_update_order",
      },
      create: false,
      del: true,
      edit: true,
      resend: true,
      message: true,
      exchange_sim: true,
      block:true,
      recovery_update_order:true,
      title: "AllOrders",
      fields: [
        {
          name: "Active",
          key: "active",
          type: "switch",
        },
        {
          name: "Number",
          key: "beneficiary_no",
          type: "input",
        },
        {
          name: "status",
          key: "status",
          type: "select",
          items: [
            { text: "تمت العملية بنجاح", value: "تمت العملية بنجاح" },
            { text: "قيد معالجة الطلب", value: "قيد معالجة الطلب" },
            { text: "لم يتم الدفع", value: "لم يتم الدفع" },
          ],
        },
        {
          name: "Character currency",
          key: "character_currency",
          type: "select",
          items: [
            { text: "EUR", value: "€" },
            { text: "USD", value: "$" },
            { text: "TRY", value: "₺" },
            { text: "CAD", value: "$" },
            { text: "AUD", value: "$" },
            { text: "AED", value: "د.إ" },
            { text: "SAR", value: "﷼" },
            { text: "QAR", value: "﷼" },
            { text: "BHD", value: "BD" },
            { text: "KWD", value: "د.ك" },
            { text: "EGP", value: "£" },
            { text: "OMR", value: "﷼" },
            { text: "GBP", value: "£" },
            { text: "SEK", value: "kr" },
            { text: "NOK", value: "kr" },
            { text: "CHF", value: "CHF" },
            { text: "RUB", value: "₽" },
            { text: "DKK", value: "Kr." },
          ],
        },
        {
          name: "Currency",
          key: "currency",
          type: "select",
          items: [
            { text: "EUR", value: "EUR" },
            { text: "USD", value: "USD" },
            { text: "TRY", value: "TRY" },
            { text: "CAD", value: "CAD" },
            { text: "AUD", value: "AUD" },
            { text: "AED", value: "AED" },
            { text: "SAR", value: "SAR" },
            { text: "QAR", value: "QAR" },
            { text: "BHD", value: "BHD" },
            { text: "KWD", value: "KWD" },
            { text: "EGP", value: "EGP" },
            { text: "OMR", value: "OMR" },
            { text: "GBP", value: "GBP" },
            { text: "SEK", value: "SEK" },
            { text: "NOK", value: "NOK" },
            { text: "CHF", value: "CHF" },
            { text: "RUB", value: "RUB" },
            { text: "DKK", value: "DKK" },
          ],
        },
        {
          name: "Customer id",
          key: "customer_id",
          type: "input",
        },
        {
          name: "Message template",
          key: "message_template",
          type: "input",
        },
        {
          name: "Paymentmethod",
          key: "payment_method",
          type: "input",
        },
        {
          name: "Price id",
          key: "price_id",
          type: "input",
        },
        {
          name: "Rest",
          key: "list_rest",
          type: "input",
        },
        {
          name: "is send message ?",
          key: "send_message",
          type: "switch",
        },
        {
          name: "type",
          key: "type_id",
          type: "input",
        },
        {
          name: "on Time",
          key: "on_time",
          type: "input",
        },
        {
          name: "response",
          key: "response",
          type: "input",
        },
      ],
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "",
          value: "operation",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: "customer id",
          value: "customer_id",
        },
        {
          text: "rest",
          value: "list_rest",
        },
        {
          text: "PayPal",
          value: "response_relation.result",
        },
        
        {
          text: "response",
          value: "response",
        },
        {
          text: "unite",
          value: "pricing.units",
        },
        {
          text: "beneficiary_no",
          value: "beneficiary_no",
        },
        {
          text: "status",
          value: "status_order",
        },
        
        {
          text: "type phone",
          value: "type_phone",
        },
        {
          text: "Price Now",
          value: "price_now",
        },
       
        {
          text: "friends",
          value: "friend.error_response",
        },
        {
          text: "profit",
          value: "profit",
        },
        {
          text: "PayPal Account",
          value: "paypal_name",
        },{
          text: "payment method",
          value: "response_relation.payment_method",
        },
        {
          text: "created_at",
          value: "created_at",
        },

        // {
        //   text: "",
        //   value: "actions",
        //   sortable: false,
        // },
      ],
    };
  },
  methods: {
    setForm(val) {
      this.id = val.id;
      let form = {
        send_message: null,
        type_id: null,
        rest: null,
        price_id: null,
        status: null,
        payment_method: null,
        message_template: null,
        customer_id: null,
        country: null,
        character_currency: null,
        currency: null,
        beneficiary_no: null,
        active: null,
      };
      this.$store.dispatch("initForm", form);
      if (val != null) {
        this.isNew = false;
        this.$store.dispatch("setForm", val);
      } else {
        this.isNew = true;
      }
      this.dialog_form = true;
    },
  },
  //  mounted() {
  //   this.$store.dispatch('initForm', this.form)
  //  }
};
</script>
